import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [],
    children: [
      { path: '', redirectTo: '/public', pathMatch: 'full' },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'public',
        component: MainLayoutComponent,
        loadChildren: () =>
          import('./public/public.module').then(
            (m) => m.PublicModule
          )
      },
      { path: '**', component: Page404Component },
      //     {
      //       path: 'dashboard',
      //       loadChildren: () =>
      //         import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      //     },
      //     {
      //       path: 'profile',
      //       loadChildren: () =>
      //         import('./profile/profile.module').then((m) => m.ProfileModule)
      //     },
      //   ]
      // },
      {
        path: 'authentication',
        component: AuthLayoutComponent,
        loadChildren: () =>
          import('./authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          )
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
