<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul id="sidebarnav" class="list" [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}" [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class="image">
              <img *ngIf="api.user.googlepicture" [src]="api.user.googlepicture" class="img-circle user-img-circle" [alt]="api?.user?.l" />
              <i *ngIf="!api.user.googlepicture" class="fas fa-user"></i>
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name"> {{api?.user?.l || 'Demo user'}} </div>
            <!-- <div class="profile-usertitle-job "> {{api?.user?.tenant?.name}} </div> -->
          </div>
        </li>
        <!-- Top Most level menu -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0" *ngFor="let sidebarItem of sidebarItems"
          [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;" [ngClass]="[sidebarItem.class]"
            (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
            <i *ngIf="sidebarItem.icon" [class]="sidebarItem.icon"></i>
            <!--i-feather [name]="sidebarItem.icon" class="sidebarIcon"></i-feather-->
            <span class="hide-menu">{{sidebarItem.title | translate}}
            </span>
          </a>
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li style="border: 2px solid #ccc;margin-bottom: 10px;border-radius: 8px;margin: 4px;" [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''" *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active bg-light'">
              <i class="fa text-dark fa-comments" style="float: right;padding: 5px;cursor: pointer;" (click)="openDialog()"></i>
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path+(api.currentUser ? ('/'+api.currentUser):'')]" (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                <i *ngIf="sidebarSubItem.icon" [class]="sidebarSubItem.icon"></i>
                {{sidebarSubItem.title | translate}}
                <div *ngIf="getPercent(sidebarSubItem.id)" class="progress m-t-2">
                  <div class="progress-bar l-bg-green shadow-style" [style.width]="getPercent(sidebarSubItem.id)">{{getPercent(sidebarSubItem.id)}}</div>
                  <div class="progress-bar l-bg-red shadow-style" [style.width]="getPercentRed(sidebarSubItem.id)">{{getPercentRed(sidebarSubItem.id)}}</div>
                </div>

              </a>
              <!-- <div class="info-box5 animate-bar bg-b-purple">
                <div class="knob-icon">
                  <circle-progress
                  [percent]="40"
                  [renderOnClick]="false"
                  [subtitle]="'Yes' | translate"
                  ></circle-progress>
                </div>
                <div class="info-box-content">
                    <span class="info-box-text">Projects</span>
                    <div class="progress m-t-20">
                        <div class="progress-bar l-bg-green shadow-style width-per-45" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span class="progress-description">
                        <small>10% Increase in 28 Days</small>
                    </span>
                </div> 
            </div>-->
              <!-- Second level menu -->
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
