<div class="historyDialog" style="min-width: 60vw;min-height: 500px;">
	<i class="fa text-dark fa-times" style="float: right;cursor: pointer;padding: 5px;" (click)="dialogRef.close()"></i>
	<h3 mat-dialog-title>{{'Discussion'}}</h3>
	<div mat-dialog-content>

		<div class="row">
			<div class="col-6">
				<img src="assets/vote.jpg"/>
			</div>
			<div class="col-6">
				<div class="discussion" style="height: 400px;">
					<div class="counter-box">
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
					</div>
					<div class="counter-box">
						Illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
					</div>
				</div>
		
				<div class="d-flex" style="align-items: baseline;">
					<div class="form-group form-float">
						<div class="form-line">
								<textarea class="form-control" placeholder="Please enter your opinion"></textarea>
						</div>
				</div>
				<button type="button" class="btn btn-primary m-t-15 waves-effect">SEND</button>	
				</div>
		
		
			</div>
		</div>
		

	</div>

</div>
